import React, { useState } from 'react'
import { Flex, useToast, useDisclosure, Box, Text } from '@chakra-ui/react'
import { ContentsListCard } from 'v2components/Common/ContentsListCard'
import { ContentsListBar } from 'v2components/Common/ContentsListBar'
import { DeleteModal } from 'v2components/Modal/DeleteModal'
import { PreviewModal } from 'v2components/Modal/PreviewModal'
import { NoData } from 'components/Common/NoData'
import { Enquete, Quiz, Playlist } from 'types/generated/graphql'
import { CloseIcon } from '@chakra-ui/icons'
import { useDeleteEnquete } from 'v2hooks/useDeleteEnquete'
import { useDeleteQuiz } from 'v2hooks/useDeleteQuiz'
import { useDeleteQuizPlaylist } from 'v2hooks/useDeleteQuizPlaylist'
import { useQueryStore } from 'v2state/SearchQueryStore'
import { SearchItems } from 'utils/searchItems'
import { AlertModal } from 'v2components/Modal/AlertModal'

export interface Props {
  enquetes?: Enquete[]
  quizzes?: Quiz[]
  quizLists?: Playlist[]
  isEditable: boolean
  setItemId?: (itemId: string) => void
  activeItemId?: string
  closeInfoFlag: boolean
  handleClickToggleInfo: (flg: boolean) => void
  isListView?: boolean
}
export interface Item {
  id: string
  title: string
  imageUrl: string | undefined | null
  description: string | undefined | null
  published?: boolean
  updatedAt?: string
  userName?: string
  iconImg?: string | undefined | null
  totalCount?: number
}

let contentType: 'enquete' | 'quiz' | 'quizlist'

export const ContentsList: React.FC<Props> = ({
  enquetes,
  quizzes,
  quizLists,
  isEditable,
  setItemId,
  closeInfoFlag,
  activeItemId,
  handleClickToggleInfo,
  isListView = true,
}) => {
  const items: Item[] = []
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false)
  const [previewItemId, setPreviewItemId] = useState('')
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [deleteItemId, setDeleteItemId] = useState('')
  const query = useQueryStore((state) => state.query)
  const alertDisclosure = useDisclosure()
  const [alertDescription, setAlertDescription] = useState('')

  // 各アイテムのデータを整形
  if (enquetes && enquetes.length) {
    contentType = 'enquete'
    const fuseObj = new SearchItems<Enquete>(enquetes, contentType)
    const searchEnquetes = fuseObj.search(query)
    for (let i = 0; i < searchEnquetes.length; i++) {
      let totalCount = 0
      const answers = searchEnquetes[i]?.answers
      if (answers && answers.length) {
        answers.forEach((value) => {
          if (value && value.count) {
            totalCount = totalCount + value.count
          }
        })
      }
      items[i] = {
        id: searchEnquetes[i].id,
        title: searchEnquetes[i].title,
        imageUrl: searchEnquetes[i].imageUrl ? searchEnquetes[i].imageUrl : '',
        description: searchEnquetes[i].question,
        published: searchEnquetes[i].published,
        updatedAt: searchEnquetes[i].updatedAt,
        userName: searchEnquetes[i].updateUser?.name,
        iconImg: searchEnquetes[i].updateUser?.icon,
        totalCount: totalCount,
      }
    }
  } else if (quizzes && quizzes.length) {
    contentType = 'quiz'
    const fuseObj = new SearchItems<Quiz>(quizzes, contentType)
    const searchQuizzes = fuseObj.search(query)
    for (let i = 0; i < searchQuizzes.length; i++) {
      let totalCount = 0
      const answers = searchQuizzes[i]?.answers
      if (answers && answers.length) {
        answers.forEach((value) => {
          if (value && value.count) {
            totalCount = totalCount + value.count
          }
        })
      }
      items[i] = {
        id: searchQuizzes[i].id,
        title: searchQuizzes[i].content.question,
        imageUrl: searchQuizzes[i].content.imageUrl,
        description: '',
        published: searchQuizzes[i].published,
        updatedAt: searchQuizzes[i].updatedAt,
        userName: searchQuizzes[i].updateUser?.name,
        iconImg: searchQuizzes[i].updateUser?.icon,
        totalCount: totalCount,
      }
    }
  } else if (quizLists && quizLists.length) {
    contentType = 'quizlist'
    const fuseObj = new SearchItems<Playlist>(quizLists, contentType)
    const searchQuizlists = fuseObj.search(query)
    for (let i = 0; i < searchQuizlists.length; i++) {
      items[i] = {
        id: searchQuizlists[i].id,
        title: searchQuizlists[i].name,
        imageUrl: searchQuizlists[i].imageUrl,
        description: searchQuizlists[i].description,
        published: searchQuizlists[i].published,
        updatedAt: searchQuizlists[i].updatedAt,
        userName: searchQuizlists[i].updateUser?.name,
        iconImg: searchQuizlists[i].updateUser?.icon,
      }
    }
  }

  const handleActiveItem = (itemId: string) => {
    if (activeItemId !== itemId) {
      if (setItemId) {
        setItemId(itemId)
      }
    }
    if (closeInfoFlag) {
      handleClickToggleInfo(false)
    }
  }

  const handleOpenPreviewModal = (itemId: string) => {
    setPreviewItemId(itemId)
    setIsOpenPreviewModal(true)
  }
  const handleClosePreviewModal = () => {
    setPreviewItemId('')
    setIsOpenPreviewModal(false)
  }

  const clipboardCopyToast = useToast()
  const closeToast = (toastId: string | number) => {
    clipboardCopyToast.close(toastId)
  }

  const addToast = (message: string) => {
    const newToastId = clipboardCopyToast({
      title: message,
      duration: 3000,
      isClosable: true,
      position: 'bottom-left',
      containerStyle: {
        minWidth: '0',
      },
      render: () => (
        <Flex
          color="white"
          p="16px"
          bg="#001C41"
          lineHeight="1"
          justify="space-between"
          align="center"
          minW="200px"
        >
          {message}
          <CloseIcon
            ml="16px"
            w="12px"
            h="12px"
            cursor="pointer"
            onClick={() => newToastId && closeToast(newToastId)}
          />
        </Flex>
      ),
    })
  }

  const handleCopyUrl = (itemId: string) => {
    const { origin } = new URL(window.location.href)
    let src = ''
    if (contentType === 'enquete') {
      src = `${origin}/${contentType}/${itemId}/embed/answer`
    } else if (contentType === 'quiz') {
      src = `${origin}/${contentType}/${itemId}/embed/play`
    } else if (contentType === 'quizlist') {
      src = `${origin}/quizplaylist/${itemId}/embed/play`
    }
    navigator.clipboard
      .writeText(src)
      .then(() => {
        addToast('URLをコピーしました')
      })
      .catch(() => {
        addToast('URLのコピーに失敗しました')
      })
  }

  const handleCopyEmbedCode = (itemId: string) => {
    const { origin } = new URL(window.location.href)
    // iframe埋込時に表示される枠の高さを最後にプラスする
    // const height = getEmbedHeight(enquete) + 25
    let src = ''
    if (contentType === 'enquete') {
      src = `${origin}/${contentType}/${itemId}/embed/answer`
    } else if (contentType === 'quiz') {
      src = `${origin}/${contentType}/${itemId}/embed/play`
    } else if (contentType === 'quizlist') {
      src = `${origin}/quizplaylist/${itemId}/embed/play`
    }
    const code = `<script>window.addEventListener('message', function (e) { if (e.origin !== '${origin}') {return}; const itemId = '${itemId}'; const iframe = document.getElementById('${itemId}'); if(itemId === e.data[1]) { iframe.height = e.data[0] + 'px'; } }, false); </script><iframe id="${itemId}" src="${src}" height="480px" width="100%" scrolling="no" frameBorder="no"></iframe>`
    navigator.clipboard
      .writeText(code)
      .then(() => {
        addToast('埋め込み用コードをコピーしました')
      })
      .catch(() => {
        addToast('埋め込み用コードのコピーに失敗しました')
      })
  }

  const onOpenDeleteModal = () => setIsOpenDeleteModal(true)
  const onCloseDeleteModal = () => setIsOpenDeleteModal(false)
  const handleOpenDeleteModal = (itemId: string) => {
    setDeleteItemId(itemId)
    onOpenDeleteModal()
  }
  const handleCloseDeleteModal = () => {
    setDeleteItemId('')
    onCloseDeleteModal()
  }

  const deleteEnquete = useDeleteEnquete(deleteItemId, onCloseDeleteModal)
  const deleteQuiz = useDeleteQuiz(deleteItemId, onCloseDeleteModal)
  const deleteQuizList = useDeleteQuizPlaylist(deleteItemId, onCloseDeleteModal)
  const HandleDeleteItem = async () => {
    try {
      if (contentType === 'enquete') {
        await deleteEnquete()
      } else if (contentType === 'quiz') {
        await deleteQuiz()
      } else if (contentType === 'quizlist') {
        await deleteQuizList()
      }
      handleClickToggleInfo(true)
    } catch (error) {
      if (contentType === 'quiz') {
        setAlertDescription('※クイズリストで選択中の場合は削除できません。')
      } else {
        setAlertDescription('')
      }
      onCloseDeleteModal()
      alertDisclosure.onOpen()
    }
  }

  if (items && items.length > 0) {
    return (
      <>
        <Box
          display={isListView ? 'block' : 'flex'}
          fontSize="14px"
          flexWrap="wrap"
          gap="16px"
        >
          {isListView && (
            <Box
              color="#222"
              fontSize="14px"
              lineHeight="1.7"
              backgroundColor="#fff"
              display="flex"
              position="sticky"
              top="0"
              zIndex="1"
              borderBottom="1px solid #ccc"
            >
              <Box
                flexGrow={1}
                flexShrink={1}
                flexBasis="268px"
                justifyContent="center"
                paddingLeft="8px"
                paddingRight="12px"
                paddingY="8px"
                overflow="hidden"
                flexDirection="column"
              >
                <Text
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                >
                  タイトル・問題文・名前
                </Text>
              </Box>
              <Box
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                flexBasis="132px"
                paddingLeft="12px"
                paddingRight="12px"
                paddingY="8px"
              >
                最終更新者
              </Box>
              <Box
                whiteSpace="nowrap"
                flexGrow={0}
                flexShrink={0}
                flexBasis="52px"
                paddingY="8px"
                paddingX="12px"
              >
                公開
              </Box>
              <Box
                whiteSpace="nowrap"
                flexGrow={0}
                flexShrink={0}
                flexBasis="102px"
                paddingY="8px"
                paddingX="12px"
              >
                最終更新
              </Box>
              {contentType !== 'quizlist' && (
                <Box
                  whiteSpace="nowrap"
                  flexBasis="82px"
                  paddingY="8px"
                  paddingX="12px"
                >
                  総回答数
                </Box>
              )}
              <Box flexBasis="42px" pl="4px" pr="8px" paddingY="8px"></Box>
            </Box>
          )}

          {!isListView
            ? items.map((itemData, index) => (
                <ContentsListCard
                  key={index}
                  itemId={itemData.id}
                  itemData={itemData}
                  contentType={contentType}
                  isEditable={isEditable}
                  activeItemId={activeItemId}
                  onClick={handleActiveItem}
                  handleOpenPreviewModal={handleOpenPreviewModal}
                  handleCopyUrl={handleCopyUrl}
                  handleCopyEmbedCode={handleCopyEmbedCode}
                  handleOpenDeleteModal={handleOpenDeleteModal}
                />
              ))
            : items.map((itemData, index) => (
                <ContentsListBar
                  key={index}
                  itemId={itemData.id}
                  itemData={itemData}
                  contentType={contentType}
                  isEditable={isEditable}
                  activeItemId={activeItemId}
                  onClick={handleActiveItem}
                  handleOpenPreviewModal={handleOpenPreviewModal}
                  handleCopyUrl={handleCopyUrl}
                  handleCopyEmbedCode={handleCopyEmbedCode}
                  handleOpenDeleteModal={handleOpenDeleteModal}
                />
              ))}
        </Box>

        <PreviewModal
          pmIsOpen={isOpenPreviewModal}
          onClosePm={handleClosePreviewModal}
          itemId={previewItemId}
          contentType={contentType}
        />

        <DeleteModal
          dmIsOpen={isOpenDeleteModal}
          dmOnClose={handleCloseDeleteModal}
          handleDeleteItem={HandleDeleteItem}
        />

        <AlertModal
          modalBody={'削除に失敗しました。'}
          modalDescription={alertDescription}
          isOpen={alertDisclosure.isOpen}
          onClose={alertDisclosure.onClose}
        />
      </>
    )
  } else {
    return <NoData />
  }
}
