import React, { useCallback, useMemo, useRef, useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import { Box, Flex, Image, Text, VStack } from '@chakra-ui/react'
import { CalendarIcon } from '@chakra-ui/icons'

import { Loading } from 'components/Common/Loading'
import { NoData } from 'components/Common/NoData'
import { Private } from 'components/Common/Private'

import { useQuery, useMutation } from '@apollo/client'
import { ENQUETE, EnqueteData, EnqueteVars } from 'graphql/apollo/query/enquete'
import {
  ENQUETE_STATS,
  EnqueteStatsData,
  EnqueteStatsVars,
} from 'graphql/apollo/query/enqueteStats'
import {
  ANSWER_ENQUETE,
  AnswerEnqueteData,
  AnswerEnqueteVars,
} from 'graphql/apollo/mutation/answer/answerEnquete'
import {
  getAnsweredEnquete,
  getEmbedHeight,
  isAnsweredEnquete,
  saveAnsweredEnquete,
} from 'utils/answeredEnquete'
import { EmbedHorizontalLine } from 'components/AnswerEnquete/EmbedHorizontalLine'
import dayjs from 'dayjs'

type Props = RouteComponentProps<{
  enqueteId: string
  isPreview?: boolean
}>

export const EmbedAnswerEnquete: React.FC<Props> = ({
  enqueteId,
  isPreview = false,
}) => {
  const elm = useRef<HTMLInputElement>(null)
  const [answered, setAnswered] = useState<string>(
    useMemo(() => {
      if (!isPreview && enqueteId && isAnsweredEnquete(enqueteId)) {
        return getAnsweredEnquete(enqueteId)
      }
      return ''
    }, [isPreview, enqueteId])
  )
  const [answerRendered, setAnswerRendered] = useState<boolean>(false)
  const [isSkippedAnswer, setIsSkippedAnswer] = useState(false)
  const [answerEnquete] = useMutation<AnswerEnqueteData, AnswerEnqueteVars>(
    ANSWER_ENQUETE
  )
  const {
    loading: enqueteLoading,
    error: enqueteError,
    data: enqueteData,
  } = useQuery<EnqueteData, EnqueteVars>(ENQUETE, {
    variables: {
      id: enqueteId!,
    },
    context: {
      noAuthorizationHeader: !isPreview,
    },
  })
  const {
    loading: enqueteStatsLoading,
    data: enqueteStatsData,
    refetch,
  } = useQuery<EnqueteStatsData, EnqueteStatsVars>(ENQUETE_STATS, {
    variables: {
      enqueteId: enqueteId!,
    },
  })
  const handleSelectedChoice = useCallback(
    async (selectedChoice, allowDuplicateAnswer) => {
      if (
        !isPreview &&
        enqueteId &&
        (!isAnsweredEnquete(enqueteId) || allowDuplicateAnswer)
      ) {
        await answerEnquete({
          variables: {
            input: {
              answer: selectedChoice,
              enqueteId: enqueteId,
            },
          },
        })
        if (!allowDuplicateAnswer) {
          saveAnsweredEnquete(enqueteId, selectedChoice)
        }
        await refetch()
      }
      setAnswered(selectedChoice)
      setAnswerRendered(true)
    },
    [answerEnquete, enqueteId, isPreview, refetch]
  )
  const enquete = enqueteData?.enquete
  const isExpired = useMemo(() => {
    if (!enquete?.deadline) {
      return false
    }
    return new Date() >= new Date(enquete.deadline)
  }, [enquete])

  /* 
  ■答えられる
  - 答えていない
  - 過去に答えているが多重投票が許可されている
  ■答えられない
  - 答えている
  - 過去に答えていて多重投票が許可されているが今回の表示では回答済み
  */
  const canAnswer =
    !isExpired &&
    (!answered || (!!enquete?.allowDuplicateAnswer && !answerRendered)) &&
    !isSkippedAnswer

  if (enqueteLoading || enqueteStatsLoading) return <Loading />
  if (enqueteError) navigate('/404NotFound')
  if (!enqueteData || !enquete || !enqueteStatsData) return <NoData />
  if (!isPreview && !enqueteData.enquete.published) {
    return <Private category="アンケート" />
  }

  const setEmbedWindowHeight = (enqueteID: string) => {
    if (!elm.current) {
      return
    }
    const { clientHeight } = elm.current
    // iframe埋込時に表示される枠の高さを最後にプラスする
    window.parent.postMessage([clientHeight, enqueteID], '*')
  }

  return (
    <VStack w="100%" onLoad={() => setEmbedWindowHeight(enquete.id)} ref={elm}>
      <Box w="100%">
        <Box h="100%" position="relative" borderRadius="16px" overflow="hidden">
          <Image
            position="absolute"
            w="100%"
            h="100%"
            objectFit="cover"
            filter="brightness(65%)"
            src={enquete.imageUrl || `/images/whiteImage.png`}
            alt={enquete.question}
          />
          <VStack
            w="100%"
            h="100%"
            minHeight={getEmbedHeight(enquete)}
            spacing={4}
            justify="center"
            position="relative"
            color="white"
            p={{ base: '10px 15px', sm: '15px 30px' }}
          >
            <Text
              fontSize={{ base: '18px', sm: '22px' }}
              mt="16px"
              mb=".4rem"
              display="flex"
              fontWeight="700"
              textShadow="0 0 5px rgba(0, 0, 0, 0.5)"
              _before={{
                content: '"Q"',
                textShadow: 'none',
                w: '1.8em',
                minWidth: '1.8em',
                h: '1.8em',
                borderRadius: '50%',
                fontSize: '70%',
                fontWeight: '800',
                mr: '.3em',
                mt: '.25em',
                bg: '#ffaf00',
                color: '#fff',
                textAlign: 'center',
              }}
            >
              {enquete.question}
            </Text>

            {!answered ||
            (!answerRendered && enquete.allowDuplicateAnswer) ||
            enquete.showResult ? (
              enqueteStatsData.enqueteStats.stats.map((stats, idx) => (
                <EmbedHorizontalLine
                  key={idx}
                  enquete={enquete}
                  stats={stats}
                  onSelectedChoice={handleSelectedChoice}
                  canAnswer={canAnswer}
                  answered={answered}
                />
              ))
            ) : (
              <Text
                fontSize={{ base: '16px', sm: '20px' }}
                fontWeight="700"
                textShadow="0 0 5px rgba(0, 0, 0, 0.5)"
              >
                {enquete.resultText || '投票ありがとうございます！'}
              </Text>
            )}
            <Flex w="100%" flexDirection="column">
              <Flex
                justifyContent="flex-end"
                alignItems="center"
                textShadow="0 0 5px rgba(0, 0, 0, 0.5)"
              >
                {enquete.periodDisplayFlag && (
                  <>
                    <Text fontSize="xs" color={'whiteAlpha.800'} mr={1}>
                      <CalendarIcon boxSize="12px" mr={1} mb={1} />
                      {enquete.startDate &&
                        dayjs(enquete.startDate).format('YYYY-MM-DD')}
                    </Text>
                    <Text mr={1} color={'whiteAlpha.800'}>
                      ~
                    </Text>
                    <Text fontSize="xs" color={'whiteAlpha.800'}>
                      {dayjs(enquete.deadline).format('YYYY-MM-DD')}
                    </Text>
                  </>
                )}
              </Flex>
              <Flex justifyContent="flex-end">
                {enquete.showResult ? (
                  <Text
                    textAlign="left"
                    fontSize="sm"
                    onClick={() => {
                      setIsSkippedAnswer(true)
                      setAnswerRendered(true)
                    }}
                    textDecoration="underline"
                    cursor="pointer"
                    textShadow="0 0 5px rgba(0, 0, 0, 0.5)"
                    display={canAnswer ? 'block' : 'none'}
                  >
                    投票せずに結果を見る
                  </Text>
                ) : (
                  <></>
                )}
                {!canAnswer && enquete.numberOfVoteDisplayFlag && (
                  <Text
                    textAlign="left"
                    fontSize="sm"
                    textShadow="0 0 5px rgba(0, 0, 0, 0.5)"
                  >
                    総投票数: {enqueteStatsData.enqueteStats.total}
                  </Text>
                )}
              </Flex>
            </Flex>
          </VStack>
        </Box>
      </Box>
    </VStack>
  )
}
