import React, { useRef } from 'react'
import { Box, Image, VStack } from '@chakra-ui/react'
import { Quiz } from 'types/generated/graphql'
import { getEmbedHeight } from 'utils/answeredQuiz'

export interface LayoutEmbedQuizProps {
  quiz: Quiz
  imageUrl: string
}

export const LayoutEmbedQuiz: React.FC<LayoutEmbedQuizProps> = ({
  children,
  quiz,
  imageUrl,
}) => {
  const elm = useRef<HTMLInputElement>(null)
  const setEmbedWindowHeight = (quizId: string) => {
    if (!elm.current) {
      return
    }
    const { clientHeight } = elm.current
    setTimeout(() => {
      // iframe埋込時に表示される枠の高さを最後にプラスする
      window.parent.postMessage([clientHeight, quizId], '*')
    }, 500)
  }
  return (
    <Box
      w="100%"
      m="auto"
      onLoad={() => setEmbedWindowHeight(quiz.id)}
      ref={elm}
    >
      <Box
        w="100%"
        h="100%"
        position="relative"
        borderRadius="16px"
        overflow="hidden"
      >
        <Image
          position="absolute"
          w="100%"
          h="100%"
          objectFit="cover"
          src={imageUrl || '/images/whiteImage.png'}
          alt={quiz.content.question}
          filter="brightness(65%)"
        />
        <VStack
          w="100%"
          //   h="100vh"
          minHeight={getEmbedHeight(quiz)}
          justify="center"
          position="relative"
          color="white"
          p="15px 30px"
          fontSize={{ base: 'md', sm: 'lg' }}
        >
          {children}
        </VStack>
      </Box>
    </Box>
  )
}
