import React from 'react'
import {
  VStack,
  Text,
  Button,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react'
import type { Playlist } from 'types/generated/graphql'
import { LayoutEmbedQuizPlaylist } from '../Common/LayoutEmbedQuizPlaylist'

type Props = {
  isLoading: boolean
  playlist?: Playlist
  quizzesCount: number
  onStart: () => void
}

export const EmbedStart: React.FC<Props> = ({
  isLoading,
  playlist,
  quizzesCount,
  onStart,
}) => {
  const buttonNextColor = useColorModeValue('#ffaf00', 'buttonNextDark')

  return (
    <LayoutEmbedQuizPlaylist
      imageUrl={playlist?.imageUrl!}
      alt={playlist?.name!}
      quizListId={playlist?.id!}
    >
      <VStack w="100%" spacing={3}>
        <Heading
          fontSize="xl"
          mb="1rem"
          color="white"
          display="flex"
          alignItems="center"
          _before={{
            content: '"Q"',
            display: 'inline-block',
            textShadow: 'none',
            w: '1.8em',
            minWidth: '1.8em',
            h: '1.8em',
            lineHeight: '1.5em',
            borderRadius: '6px',
            fontSize: '70%',
            fontWeight: '800',
            mr: '.3em',
            mt: '.25em',
            bg: '#ffaf00',
            color: '#fff',
            textAlign: 'center',
          }}
        >
          {playlist?.name}
        </Heading>
        <Text
          mb="1rem"
          fontSize="18px"
          display="flex"
          fontWeight="800"
          textShadow="0 0 5px rgba(0, 0, 0, 0.5)"
          lineHeight="1.6em"
          color="#fff"
          m="0 0 0.3em"
          minWidth="100%"
          textAlign="center"
        >
          {playlist?.description}
        </Text>

        {!isLoading && quizzesCount && (
          <Heading fontSize="xl" color="white">
            全{quizzesCount}問
          </Heading>
        )}

        <Button
          isLoading={isLoading}
          bg={buttonNextColor}
          _hover={{ bg: '#eb7917' }}
          color="#fff"
          fontSize="18px"
          fontWeight="700"
          p="10px"
          textDecoration="none"
          borderRadius="6px"
          w="8em"
          maxW="100%"
          boxSizing="border-box"
          onClick={onStart}
        >
          スタート
        </Button>
      </VStack>
    </LayoutEmbedQuizPlaylist>
  )
}
