import React from 'react'
import {
  HStack,
  IconButton,
  useToast,
  Flex,
  Tooltip,
  ToastId,
} from '@chakra-ui/react'
import {
  MdRemoveRedEye,
  MdInsertLink,
  MdCode,
  MdCreate,
  MdDeleteForever,
} from 'react-icons/md'
import { CloseIcon } from '@chakra-ui/icons'
import { useNavigate } from '@reach/router'

interface Props {
  itemId?: string
  contentType: 'quiz' | 'quizlist' | 'enquete'
  buttonType: 'comp-modal' | 'item-info' // インフォエリア詳細実装時にボタン種類の出しわけに使用
  handleOpenPreviewModal: (itemId: string) => void
  onCloseCm?: () => void
  handleOpenDeleteModal?: () => void
}

export const ToolButtons: React.FC<Props> = ({
  itemId,
  contentType,
  buttonType,
  handleOpenPreviewModal,
  onCloseCm,
  handleOpenDeleteModal,
}) => {
  const navigate = useNavigate()
  const clipboardCopyToast = useToast()
  const closeToast = (toastId: ToastId) => {
    clipboardCopyToast.close(toastId)
  }

  const addToast = (message: string) => {
    const newToastId = clipboardCopyToast({
      title: message,
      duration: 3000,
      isClosable: true,
      position: 'bottom-left',
      containerStyle: {
        minWidth: '0',
      },
      render: () => (
        <Flex
          color="white"
          p="16px"
          bg="#001C41"
          lineHeight="1"
          justify="space-between"
          align="center"
          minW="200px"
        >
          {message}
          <CloseIcon
            ml="16px"
            w="12px"
            h="12px"
            cursor="pointer"
            onClick={() => newToastId && closeToast(newToastId)}
          />
        </Flex>
      ),
    })
  }

  const handleCopyUrl = (itemId: string) => {
    const { origin } = new URL(window.location.href)
    let src = ''
    if (contentType === 'enquete') {
      src = `${origin}/${contentType}/${itemId}/embed/answer`
    } else if (contentType === 'quiz') {
      src = `${origin}/${contentType}/${itemId}/embed/play`
    } else if (contentType === 'quizlist') {
      src = `${origin}/quizplaylist/${itemId}/embed/play`
    }
    navigator.clipboard
      .writeText(src)
      .then(() => {
        addToast('URLをコピーしました')
      })
      .catch(() => {
        addToast('URLのコピーに失敗しました')
      })
  }

  const handleCopyEmbedCode = (itemId: string) => {
    const { origin } = new URL(window.location.href)
    // iframe埋込時に表示される枠の高さを最後にプラスする
    // const height = getEmbedHeight(enquete) + 25
    let src = ''
    if (contentType === 'enquete') {
      src = `${origin}/${contentType}/${itemId}/embed/answer`
    } else if (contentType === 'quiz') {
      src = `${origin}/${contentType}/${itemId}/embed/play`
    } else if (contentType === 'quizlist') {
      src = `${origin}/quizplaylist/${itemId}/embed/play`
    }
    const code = `<script>window.addEventListener('message', function (e) { if (e.origin !== '${origin}') {return}; const itemId = '${itemId}'; const iframe = document.getElementById('${itemId}'); if(itemId === e.data[1]) { iframe.height = e.data[0] + 'px'; } }, false); </script><iframe id="${itemId}" src="${src}" height="480px" width="100%" scrolling="no" frameBorder="no"></iframe>`
    navigator.clipboard
      .writeText(code)
      .then(() => {
        addToast('埋め込み用コードをコピーしました')
      })
      .catch(() => {
        addToast('埋め込み用コードのコピーに失敗しました')
      })
  }

  const handleNavigateEditPage = (itemId: string) => {
    navigate(`/v2/edit/${contentType}/${itemId}`)
  }

  return (
    <>
      <HStack spacing="12px" justifyContent="center">
        {buttonType === 'item-info' && (
          <Tooltip label="編集する" placement="top" variant="quzilla-default">
            <IconButton
              aria-label="edit"
              variant="tool-btns"
              icon={<MdCreate size="24px" />}
              onClick={() => {
                if (itemId) handleNavigateEditPage(itemId)
              }}
            />
          </Tooltip>
        )}

        <Tooltip
          label="プレビュー表示"
          placement="top"
          variant="quzilla-default"
        >
          <IconButton
            aria-label="preview"
            variant="tool-btns"
            icon={<MdRemoveRedEye size="24px" />}
            onClick={() => {
              if (itemId) handleOpenPreviewModal(itemId)
              if (onCloseCm) onCloseCm()
            }}
          />
        </Tooltip>
        <Tooltip label="URLをコピー" placement="top" variant="quzilla-default">
          <IconButton
            aria-label="copy link"
            variant="tool-btns"
            icon={<MdInsertLink size="24px" />}
            onClick={() => {
              if (itemId) handleCopyUrl(itemId)
            }}
          />
        </Tooltip>
        <Tooltip
          label="埋め込み用コードをコピー"
          placement="top"
          variant="quzilla-default"
        >
          <IconButton
            aria-label="copy code"
            variant="tool-btns"
            icon={<MdCode size="24px" />}
            onClick={() => {
              if (itemId) handleCopyEmbedCode(itemId)
            }}
          />
        </Tooltip>
        {buttonType === 'item-info' && (
          <Tooltip label="削除する" placement="top" variant="quzilla-default">
            <IconButton
              aria-label="delete"
              variant="tool-btns"
              icon={<MdDeleteForever size="24px" />}
              onClick={() => {
                if (handleOpenDeleteModal) handleOpenDeleteModal()
              }}
            />
          </Tooltip>
        )}
      </HStack>
    </>
  )
}
