import { useMemo } from 'react'
import { VStack } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { useNavigate, RouteComponentProps } from '@reach/router'
import { LayoutOpenArea } from 'components/Common/LayoutOpenArea'
import { ProgressStatus, usePlayQuizPlaylist } from 'hooks/usePlayQuizPlaylist'
import { Start } from 'components/PlayQuizPlaylist/Start'
import { Question } from 'components/PlayQuizPlaylist/Question'
import { Answered } from 'components/PlayQuizPlaylist/Answered'
import { Result } from 'components/PlayQuizPlaylist/Result'
import { EmbedStart } from 'components/PlayQuizPlaylist/EmbedStart'
import { EmbedQuestion } from 'components/PlayQuizPlaylist/EmbedQuestion'
import { EmbedAnswered } from 'components/PlayQuizPlaylist/EmbedAnswered'
import { EmbedResult } from 'components/PlayQuizPlaylist/EmbedResult'
import { Loading } from 'components/Common/Loading'
import { Private } from 'components/Common/Private'
import {
  QUIZSTATS,
  QuizStatsData,
  QuizStatsVars,
} from 'graphql/apollo/query/quizStats'
import {
  PLAYLIST,
  PlaylistData,
  PlaylistVars,
} from 'graphql/apollo/query/playlist'

type urlProps = RouteComponentProps<{
  quizPlaylistId: string
  isEmbed: boolean
}>

export const PlayQuizPlaylistOpenArea: React.FC<urlProps> = ({
  quizPlaylistId = '',
  isEmbed = false,
}) => {
  const navigate = useNavigate()
  const {
    loading,
    error,
    progressStatus,
    playlist,
    quizNo,
    answers,
    score,
    currentQuiz,
    quizzesCount,
    handleStart,
    handleAnswer,
    handleNext,
  } = usePlayQuizPlaylist(quizPlaylistId)

  const { loading: quizStatsLoading, data: quizStatsData } = useQuery<
    QuizStatsData,
    QuizStatsVars
  >(QUIZSTATS, {
    variables: {
      quizId: currentQuiz?.id!,
    },
    fetchPolicy: 'network-only',
  })

  const { data } = useQuery<PlaylistData, PlaylistVars>(PLAYLIST, {
    variables: {
      id: quizPlaylistId!,
    },
  })

  const listDesign = data?.playlist.design
  const design = currentQuiz?.design

  const designTheme = useMemo(() => {
    /**
     * TODO:
     * 埋め込みクイズのデザインが決まり次第削除予定
     */
    if (isEmbed) {
      return 'ORIGINAL'
    }

    if (
      listDesign === 'ORIGINAL' ||
      listDesign === 'SIMPLE' ||
      listDesign === 'SERIOUS'
    ) {
      return listDesign
    }

    if (design) {
      return design
    }
  }, [isEmbed, listDesign, design])

  if (loading || quizStatsLoading) return <Loading />
  if (error) {
    if (error.message.startsWith('unauthorized')) {
      return <Private category="クイズ" />
    }

    navigate('/404NotFound')
  }
  if (!playlist?.published) return <Private category="クイズ" />
  if (!currentQuiz) return <Private category="クイズ" /> // すべて非公開クイズの場合はプレイリストも非公開対応する
  const quizListId = playlist.id

  const renderPageComponent = () => {
    switch (progressStatus) {
      case ProgressStatus.START:
        if (isEmbed) {
          return (
            <EmbedStart
              isLoading={loading}
              playlist={playlist}
              quizzesCount={quizzesCount}
              onStart={handleStart}
            />
          )
        }
        return (
          <Start
            isLoading={loading}
            playlist={playlist}
            quizzesCount={quizzesCount}
            design={designTheme}
            onStart={handleStart}
          />
        )
      case ProgressStatus.QUESTION:
        if (isEmbed) {
          return (
            <EmbedQuestion
              quiz={currentQuiz}
              quizNo={quizNo}
              quizzesCount={quizzesCount}
              quizStats={quizStatsData?.quizStats}
              onAnswer={handleAnswer}
              quizListId={quizListId}
            />
          )
        }
        return (
          <Question
            quiz={currentQuiz}
            quizNo={quizNo}
            quizzesCount={quizzesCount}
            quizStats={quizStatsData?.quizStats}
            design={designTheme}
            onAnswer={handleAnswer}
          />
        )
      case ProgressStatus.ANSWERD:
        if (isEmbed) {
          return (
            <EmbedAnswered
              quiz={currentQuiz}
              quizStats={quizStatsData?.quizStats}
              answer={answers[answers.length - 1]}
              onNext={handleNext}
              quizListId={quizListId}
            />
          )
        }
        return (
          <Answered
            quiz={currentQuiz}
            quizNo={quizNo}
            quizzesCount={quizzesCount}
            quizStats={quizStatsData?.quizStats}
            answer={answers[answers.length - 1]}
            design={designTheme}
            onNext={handleNext}
          />
        )
      case ProgressStatus.RESULT:
        if (isEmbed) {
          return (
            <EmbedResult
              playlist={playlist!}
              quizzesCount={quizzesCount}
              answers={answers}
              score={score}
              quizListId={quizListId}
            />
          )
        }
        return (
          <Result
            playlist={playlist!}
            quizzesCount={quizzesCount}
            answers={answers}
            score={score}
            design={designTheme}
          />
        )
    }
  }

  return (
    <LayoutOpenArea design={designTheme}>
      <VStack bg={`${designTheme}.common.screenBackground`}>
        {renderPageComponent()}
      </VStack>
    </LayoutOpenArea>
  )
}
