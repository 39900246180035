import React from 'react'
import { css } from '@emotion/react'
import {
  VStack,
  Image,
  Heading,
  Center,
  Text,
  Stack,
  Grid,
  GridItem,
  Box,
  Button,
} from '@chakra-ui/react'
import { BsCircle } from 'react-icons/bs'
import { ImCross } from 'react-icons/im'
import type { Quiz, Stats, ListDesignType } from 'types/generated/graphql'

const ImageWrapper = css`
  padding-top: 100%;
  position: relative;
  height: 100%;
`
const ImageBox = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #f3b0ae;
`
const ImageWrapperChoice = css`
  padding-top: 40%;
  position: relative;
  height: 100%;
`
const ImageBoxChoice = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`

type Props = {
  quiz: Quiz & { answerCandidates: string[] }
  quizNo: number
  quizzesCount: number
  quizStats?: Stats
  answer: string
  design?: ListDesignType
  onNext: () => void
}

export const Answered: React.FC<Props> = ({
  quiz,
  quizNo,
  quizzesCount,
  quizStats,
  answer,
  design,
  onNext,
}) => {
  return (
    <VStack w="100%">
      <Heading fontSize="xl" mb="1rem" color={`${design}.common.mainText`}>
        問題{quizNo}/{quizzesCount}
      </Heading>
      {answer === quiz.content.correct ? (
        <Heading
          as="h3"
          size="lg"
          mb="1rem"
          color={`${design}.answered.result.correctLabel`}
        >
          正解
        </Heading>
      ) : (
        <Heading
          as="h3"
          size="lg"
          mb="1rem"
          color={`${design}.answered.result.incorrectLabel`}
        >
          不正解
        </Heading>
      )}
      {design === 'SIMPLE' || design === 'SERIOUS' ? (
        <Box w={{ base: '100%' }} mb="0.5rem">
          <Box css={ImageWrapperChoice}>
            <Image
              css={ImageBoxChoice}
              src={
                quiz.content.answerImageUrl ||
                quiz.content.imageUrl ||
                '/images/whiteImage.png'
              }
              alt="リスト画像"
            />
          </Box>
        </Box>
      ) : (
        <Box w={{ base: '100%', sm: '60%', lg: '50%' }} mb="0.5rem">
          <Box css={ImageWrapper}>
            <Image
              css={ImageBox}
              src={
                quiz.content.answerImageUrl ||
                quiz.content.imageUrl ||
                '/images/whiteImage.png'
              }
              alt="リスト画像"
            />
          </Box>
        </Box>
      )}
      <Text
        fontSize="lg"
        mb="1rem"
        py="1rem"
        color={`${design}.common.mainText`}
      >
        Q：{quiz.content.question}
        {quizStats &&
          // statsには[正解、不正解1、不正解2…]の順番で入る
          `(正答率${quizStats.stats[0].percent})`}
      </Text>
      <Stack w="100%" mb="2rem" spacing={4} direction="row" align="center">
        <Grid
          w="100%"
          templateRows={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }}
          gap={4}
        >
          {quiz.answerCandidates.map((candidate, index) => {
            return (
              <GridItem
                w="100%"
                rowSpan={{ base: 1, md: 1 }}
                colSpan={{ base: 1, md: 2 }}
                key={'quizPlaylistAnswer-' + index}
              >
                {(() => {
                  // 正解
                  if (candidate === quiz.content.correct) {
                    return (
                      <Box
                        boxShadow="base"
                        p="4"
                        rounded="md"
                        border={design === 'SIMPLE' ? '1px' : ''}
                        borderColor={`${design}.answered.result.correctBorder`}
                        bg={`${design}.answered.choiceAnswered.background`}
                        w="100%"
                      >
                        <Center
                          color={`${design}.answered.result.correctLabel`}
                        >
                          <BsCircle />：
                          <Text fontWeight="bold">{candidate}</Text>
                        </Center>
                      </Box>
                    )
                  }

                  // 間違い
                  if (answer === candidate) {
                    return (
                      <Box
                        boxShadow="base"
                        p="4"
                        rounded="md"
                        border={design === 'SIMPLE' ? '1px' : ''}
                        borderColor={`${design}.answered.result.incorrectBorder`}
                        bg={`${design}.answered.choiceAnswered.background`}
                        w="100%"
                      >
                        <Center
                          color={`${design}.answered.result.incorrectLabel`}
                        >
                          <ImCross />：{candidate}
                        </Center>
                      </Box>
                    )
                  }
                  // 未選択
                  return (
                    <Box
                      boxShadow="base"
                      p="4"
                      rounded="md"
                      border={design === 'SIMPLE' ? '1px' : ''}
                      w="100%"
                      bg={`${design}.answered.choiceAnswered.background`}
                    >
                      <Center>{candidate}</Center>
                    </Box>
                  )
                })()}
              </GridItem>
            )
          })}
        </Grid>
      </Stack>

      {quiz.content.description && (
        <Center py={5}>
          <VStack>
            <Text
              fontSize={{ base: 'md', sm: 'lg' }}
              mb="0.5rem"
              fontWeight="bold"
              color={`${design}.common.mainText`}
              whiteSpace="nowrap"
            >
              解説
            </Text>
            <Text color={`${design}.common.mainText`}>
              {quiz.content.description}
            </Text>
          </VStack>
        </Center>
      )}

      <Button
        w="200px"
        mb={3}
        _hover={{
          color: `${design}.answered.button.afterLabel`,
          borderColor: `${design}.answered.button.afterBorder`,
          bg: `${design}.answered.button.afterBackground`,
        }}
        border={design === 'SIMPLE' ? '1px' : ''}
        borderColor={`${design}.answered.button.borderColor`}
        color={`${design}.answered.button.label`}
        bg={`${design}.answered.button.background`}
        onClick={onNext}
      >
        次へ
      </Button>
    </VStack>
  )
}
